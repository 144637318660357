import { CURRENCY_TO_COUNTRY_CODE } from "@lib/constants";
import graphqlFetch from "@lib/graphql-fetch";
import { logError } from "@lib/logger";

const CheckoutFields = `
fragment CheckoutFields on Checkout {
    id
    webUrl
    customAttributes {
      key
      value
    }
    subtotalPriceV2 {
        amount
        currencyCode
    }
    totalTaxV2 {
      amount
      currencyCode
    }
    discountApplications(first: 10) {
      edges {
        node {
          allocationMethod
          targetSelection
          targetType
        }
      }
    }
    currencyCode
    completedAt
    lineItems(first: 100) {
        edges {
            node {
              id
              title
              quantity
              customAttributes {
                key,
                value
              }
                discountAllocations {
                  allocatedAmount {
                    amount
                    currencyCode
                  }
                  discountApplication {
                    ... on ScriptDiscountApplication {
                      title
                    }
                  }
                }
                unitPrice {
                  amount
                  currencyCode
                }
                variant {
                    id
                    sku
                    title
                    quantityAvailable
                    priceV2 {
                        amount
                        currencyCode
                    }
                    compareAtPriceV2 {
                        amount
                        currencyCode
                    }
                    selectedOptions {
                        name
                        value
                    }
                    image {
                        altText
                        originalSrc
                        placeholder: transformedSrc(maxWidth: 100, preferredContentType: JPG)
                    }
                    product {
                      tags
                      handle
                      id
                    }
                }
            }
        }
    }
}
`;

const FetchCheckout = `
query FetchCheckout($id: ID!) {
  node(id: $id) {
    ... on Checkout {
      ...CheckoutFields
    }
  }
}
${CheckoutFields}
`;

const CreateCheckout = `
  mutation CreateCheckout($input: CheckoutCreateInput!,$countryCode: CountryCode) @inContext(country: $countryCode) {
    checkoutCreate(input: $input) {
      checkoutUserErrors {
        code
        field
        message
      }
      checkout {
        ...CheckoutFields
      }
    }
  }
  ${CheckoutFields}
`;

const ReplaceLineItems = `
  mutation ReplaceLineItems($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
    checkoutLineItemsReplace(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        code
        field
        message
      }
      checkout {
        ...CheckoutFields
      }
    }
  }
  ${CheckoutFields}
`;

const checkoutApplyDiscountMutation = `
  mutation checkoutDiscountCodeApplyV2($discountCode: String!, $checkoutId: ID!) {
    checkoutDiscountCodeApplyV2(
      discountCode: $discountCode
      checkoutId: $checkoutId
    ) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export async function getCheckout(id) {
  if (id) {
    const checkout = await graphqlFetch(FetchCheckout, {
      variables: {
        id,
      },
    });
    return checkout.node;
  }
  return null;
}

export async function setLineItems(checkout, lineItems, currency) {
  let updatedCheckout = null;
  if (checkout) {
    updatedCheckout = await graphqlFetch(ReplaceLineItems, {
      variables: {
        checkoutId: checkout.id,
        lineItems,
      },
    });
    return updatedCheckout.checkoutLineItemsReplace;
  }
  updatedCheckout = await graphqlFetch(CreateCheckout, {
    variables: {
      input: {
        lineItems,
        customAttributes: [
          {
            key: "defaultorderstatus",
            value: "UnConfirmed",
          },
        ],
      },
      countryCode: CURRENCY_TO_COUNTRY_CODE[currency],
    },
  });
  return updatedCheckout.checkoutCreate;
}

export async function checkoutApplyDiscount(discountCode, checkoutId) {
  try {
    await graphqlFetch(checkoutApplyDiscountMutation, {
      variables: { discountCode, checkoutId },
    });
    return true;
  } catch (err) {
    logError(err);
    return false;
  }
}
