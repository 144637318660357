"use client";

import { useContext, useReducer, createContext, useEffect } from "react";
import Cookies from "js-cookie";
import { logError } from "@lib/logger";
import { trackEvent } from "@lib/track-event";
import { useCustomerMetafields } from "@hooks/useCustomerMetafields";
import { useCurrencyState } from "@hooks/useCurrency";
import { getCheckout, setLineItems, checkoutApplyDiscount } from "@components/Cart/lib/queries";
import { addItem, updateItem, addBundleItems } from "@components/Cart/lib";

const CheckoutStateContext = createContext();
const CheckoutDispatchContext = createContext();

export const ActionType = {
  OPEN_DRAWER: "@cart/OPEN_DRAWER",
  CLOSE_DRAWER: "@cart/CLOSE_DRAWER",
  SET_CHECKOUT: "@cart/SET_CHECKOUT",
  UPDATING_CHECKOUT: "@cart/UPDATING_CHECKOUT",
};

function cartReducer(state, action) {
  switch (action.type) {
    case ActionType.OPEN_DRAWER: {
      return { ...state, isOpen: true };
    }
    case ActionType.CLOSE_DRAWER: {
      return { ...state, isOpen: false };
    }
    case ActionType.SET_CHECKOUT: {
      return { ...state, checkout: action.checkout };
    }
    case ActionType.UPDATING_CHECKOUT: {
      return {
        ...state,
        cartIsLoading: action.loading,
        loading: action.loading,
        errorMsg: action.errorMsg || "",
      };
    }
    default: {
      state;
    }
  }
}

const initialState = {
  isOpen: false,
  checkout: null,
  loading: false,
  cartIsLoading: false,
  errorMsg: "",
};

function CheckoutProvider({ children }) {
  const [state, dispatch] = useReducer(cartReducer, initialState);
  const {
    state: metafieldsState,
    addMetafield,
    updateMetafield,
    deleteMetafield,
  } = useCustomerMetafields();
  const { currency } = useCurrencyState();

  useEffect(() => {
    async function retrieveCheckout(checkout) {
      try {
        const data = await getCheckout(checkout);

        if (!data) {
          return;
        }

        if (data.completedAt) {
          Cookies.remove("kotn_checkout");
          dispatch({ type: ActionType.SET_CHECKOUT, checkout: null });
          if (metafieldsState.metafield && metafieldsState.customer_id) {
            await deleteMetafield();
          }
          return;
        }

        dispatch({ type: ActionType.SET_CHECKOUT, checkout: data });
        Cookies.set("kotn_checkout", data.id);
        if (window.discountCode) {
          await checkoutApplyDiscount(window.discountCode, data.id);
          delete window.discountCode;
        }
      } catch (error) {
        logError(error);
        dispatch({ type: ActionType.UPDATING_CHECKOUT, loading: false, errorMsg: error.message });
      }
    }

    let checkoutID = Cookies.get("kotn_checkout");
    if (metafieldsState && metafieldsState.metafield && metafieldsState.metafield.value) {
      checkoutID = metafieldsState.metafield.value;
    }

    if (checkoutID) {
      retrieveCheckout(checkoutID);
    }
  }, [metafieldsState, currency]);

  useEffect(() => {
    async function changeCurrency(items) {
      try {
        dispatch({ type: ActionType.UPDATING_CHECKOUT, loading: true });
        const updatedItems = items.map(({ node }) => {
          return {
            variantId: node.variant.id,
            quantity: node.quantity,
            customAttributes: node.customAttributes,
          };
        });
        const data = await setLineItems(null, updatedItems, currency);
        const errors = data.checkoutUserErrors ?? data.userErrors;
        if (errors.length) {
          throw errors[0];
        }
        if (data) {
          dispatch({ type: ActionType.SET_CHECKOUT, checkout: data.checkout });
          Cookies.set("kotn_checkout", data.checkout.id);
          await addMetafield(data.checkout.id);
        }

        dispatch({ type: ActionType.UPDATING_CHECKOUT, loading: false });
      } catch (error) {
        logError(error);
        dispatch({ type: ActionType.UPDATING_CHECKOUT, loading: false, errorMsg: error.message });
      }
    }

    if (
      state.checkout &&
      currency &&
      state.checkout.currencyCode.toLowerCase() !== currency.toLowerCase()
    ) {
      changeCurrency(lineItems);
    }
  }, [currency]);

  const lineItems = state.checkout?.lineItems.edges ?? [];

  const openCart = () => {
    dispatch({ type: ActionType.OPEN_DRAWER });
    document.body.style.overflow = "hidden";
  };

  const closeCart = () => {
    dispatch({ type: ActionType.CLOSE_DRAWER });
    document.body.style.overflow = "unset";
  };

  const toggleCart = () => {
    if (state.isOpen) {
      closeCart();
    } else {
      openCart();
    }
  };

  const setItems = async (items) => {
    try {
      dispatch({ type: ActionType.UPDATING_CHECKOUT, loading: true });

      const updatedItems = items.map((item) => {
        return {
          variantId: item.variantId,
          quantity: item.quantity,
          customAttributes: item.customAttributes,
        };
      });

      const data = await setLineItems(state.checkout, updatedItems, currency);

      const errors = data.checkoutUserErrors ?? data.userErrors;

      if (errors.length) {
        throw errors[0];
      }

      const updatedCheckout = {
        ...data.checkout,
        lineItems: {
          ...data.checkout.lineItems,
          edges: data?.checkout?.lineItems?.edges?.map((edge) => {
            // Check if the item exists in the current lineItems (state)
            const existingLineItem = state?.checkout?.lineItems?.edges.find(
              (existingEdge) => existingEdge?.node?.variant?.id === edge?.node?.variant?.id
            );

            // Determine which priceV2 to use
            const priceV2ToUse = existingLineItem
              ? existingLineItem?.node?.variant?.priceV2 // Use the existing price if found
              : items.find((item) => item?.variantId === edge?.node?.variant?.id)?.priceV2 ||
                edge?.node?.variant?.priceV2; // Use new price or current edge price

            return {
              ...edge,
              node: {
                ...edge.node,
                variant: {
                  ...edge.node.variant,
                  priceV2: priceV2ToUse,
                },
              },
            };
          }),
        },
      };

      await setLineItems(updatedCheckout, updatedItems, currency);

      dispatch({ type: ActionType.UPDATING_CHECKOUT, loading: false });
      dispatch({ type: ActionType.SET_CHECKOUT, checkout: updatedCheckout });

      if (metafieldsState && metafieldsState.metafield) {
        if (metafieldsState.metafield.value !== data.checkout.id) {
          updateMetafield(data.checkout.id);
        }
      } else {
        addMetafield(data.checkout.id);
      }

      Cookies.set("kotn_checkout", data.checkout.id);

      return data;
    } catch (error) {
      logError(error);
      dispatch({ type: ActionType.UPDATING_CHECKOUT, loading: false, errorMsg: error.message });
      return false;
    }
  };

  const addBundle = (bundle) => {
    const items = addBundleItems(lineItems, bundle);

    setItems(items).then((result) => {
      if (result) {
        openCart();
      }
    });
  };

  const addCartItem = (item, quantity = 1, productGroup) => {
    const items = addItem(lineItems, item, quantity, productGroup && productGroup.specialNotes);

    setItems(items).then((result) => {
      if (result) {
        trackEvent("add_to_cart", { variant: item, checkout: result.checkout, productGroup });
        openCart();
      }
    });
  };

  const updateCartItem = (item) => {
    const items = updateItem(lineItems, item);

    setItems(items);
  };

  return (
    <CheckoutStateContext.Provider value={state}>
      <CheckoutDispatchContext.Provider
        value={{ openCart, closeCart, toggleCart, updateCartItem, addCartItem, addBundle }}
      >
        {children}
      </CheckoutDispatchContext.Provider>
    </CheckoutStateContext.Provider>
  );
}

function useCheckoutState() {
  const context = useContext(CheckoutStateContext);
  if (context === undefined) {
    throw new Error("useCheckoutState must be used within a CheckoutProvider");
  }
  return context;
}

function useCheckoutDispatch() {
  const context = useContext(CheckoutDispatchContext);
  if (context === undefined) {
    throw new Error("useCheckoutDispatch must be used within a CheckoutProvider");
  }
  return context;
}

export { CheckoutProvider, useCheckoutState, useCheckoutDispatch };
