import PropTypes from "prop-types";
import styles from "./styles/content.module.css";
import { CartDrawerEmpty } from "./CartDrawerEmpty";
import { LineItem } from "../LineItem";
import { Recommendations } from "@components/Product/Recommendations";
import { TOTE } from ".";
import { useEffect } from "react";

export function CartDrawerContent({
  lineItems,
  loading = false,
  updateItem,
  location,
  handleCloseDrawer,
}) {
  const variant_ids = lineItems.map(({ node }) => node.variant.sku);

  if (lineItems.length === 0) {
    return (
      <article className={styles.container}>
        <CartDrawerEmpty location={location} />
        <Recommendations variant_ids={variant_ids} type={"checkout_multi"} isInCart emptyState />
      </article>
    );
  }

  return (
    <article className={styles.container} data-cy="cart">
      <div className={styles.wrapper}>
        <ul className={styles.list}>
          {lineItems
            .concat()
            .reverse()
            .map(({ node }) => {
              return (
                <LineItem
                  key={node.id}
                  item={node}
                  loading={loading}
                  onItemUpdate={updateItem}
                  handleCloseDrawer={handleCloseDrawer}
                />
              );
            })}
        </ul>
      </div>
      <Recommendations
        variant_ids={variant_ids}
        type={"checkout_multi"}
        isInCart
        handleCloseDrawer={handleCloseDrawer}
      />
    </article>
  );
}

CartDrawerContent.propTypes = {
  lineItems: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  updateItem: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};
